import { createContext as $, useRef as I, useState as S, useEffect as F, useContext as P } from "react";
import { j as n } from "./jsx-runtime-B6kdoens.js";
import { createPortal as b } from "react-dom";
import i from "styled-components";
import { StyledBannerContainer as w, StyledBannerContent as R, StyledBannerCloseIconButton as T } from "./components/Banner/style.es.js";
import { u as z } from "./useDisclose-DpzoV9h_.js";
import "@floating-ui/react-dom";
import { getBannerIconProps as m } from "./components/Banner/utils.es.js";
import { I as E } from "./Icon-C3nZvRjn.js";
import "./components/Icon/countryVariants.es.js";
import "./components/Theme/themes.es.js";
import "./palettes-C9VCECeM.js";
import { getZIndexes as M, getBreakpointMediaQuery as r, getPaletteBorder as p, getSpacings as g } from "./components/Theme/utils.es.js";
import { useMediaQuery as O } from "./components/Theme/hooks.es.js";
import "./CssBaseline-CQMfs2gH.js";
import "./styles-DFJgZZAt.js";
import { T as k } from "./Typography-GJu-MHyZ.js";
import { F as Q } from "./FileIcon-BhdKUMEc.js";
import "./components/FeaturedIcon/constants.es.js";
const f = $({
  openBanner: () => {
  }
}), V = i(w)`
  border: none;
  position: fixed;
  border-radius: 0;
  flex-direction: row;
  justify-content: center;
  z-index: ${M("tooltip")};
  ${r("max", "lg")} {
    bottom: 0;
    border-top: 1px solid ${p("primary")};
  }
  ${r("min", "lg")} {
    top: 0;
    border-bottom: 1px solid ${p("primary")};
  }
`, H = i(R)`
  flex-direction: row;
  align-items: center;
  gap: ${g("lg")};
  ${r("max", "lg")} {
    flex-direction: column;
    margin-left: auto;
  }
`, W = i.div`
  text-align: center;
`, Z = i(T)`
  position: absolute;
  padding: ${g("lg")};
  margin-left: 0;
  ${r("max", "lg")} {
    position: relative;
    padding: 0;
    margin-left: auto;
  }
`, so = ({ children: e }) => {
  const u = I(null), { isOpen: a, onOpen: B, onClose: x } = z(!1), s = O("mobile"), [o, y] = S(void 0), l = () => {
    document.body.style.padding = "0";
  }, h = o != null && o.variant ? m(o.variant).variant : "info-circle", v = () => {
    l(), x();
  }, j = (t) => {
    y({
      ...t,
      icon: t.icon ?? { variant: h }
    }), B();
  };
  F(() => {
    var t;
    if (a) {
      const d = (t = document.getElementById("floatingBanner")) == null ? void 0 : t.getBoundingClientRect().height;
      l(), s ? document.body.style.paddingBottom = `${d}px` : document.body.style.paddingTop = `${d}px`;
    }
    return () => {
      l();
    };
  }, [a, s]);
  const c = m(
    (o == null ? void 0 : o.variant) ?? "neutral"
  ), C = () => /* @__PURE__ */ n.jsx(n.Fragment, { children: typeof (o == null ? void 0 : o.icon) == "object" && o.icon && "variant" in o.icon ? /* @__PURE__ */ n.jsx(
    Q,
    {
      size: "sm",
      variant: "light",
      color: c.color,
      iconProps: {
        ...o.icon,
        variant: c.variant
      }
    }
  ) : o == null ? void 0 : o.icon });
  return /* @__PURE__ */ n.jsxs(
    f.Provider,
    {
      value: {
        openBanner: j
      },
      children: [
        e,
        a && b(
          /* @__PURE__ */ n.jsxs(
            V,
            {
              id: "floatingBanner",
              ref: u,
              $hasChildren: !!e,
              children: [
                /* @__PURE__ */ n.jsxs(H, { children: [
                  !s && /* @__PURE__ */ n.jsx(C, {}),
                  /* @__PURE__ */ n.jsx(W, { children: /* @__PURE__ */ n.jsx(k, { size: "sm", weight: "semibold", color: "secondary", children: o == null ? void 0 : o.title }) }),
                  o == null ? void 0 : o.children
                ] }),
                /* @__PURE__ */ n.jsx(
                  Z,
                  {
                    color: "gray",
                    variant: "text",
                    onClick: v,
                    children: /* @__PURE__ */ n.jsx(E, { variant: "close" })
                  }
                )
              ]
            }
          ),
          document.body
        )
      ]
    }
  );
}, lo = () => {
  const { openBanner: e } = P(f);
  return { openBanner: e };
};
export {
  so as B,
  lo as u
};
